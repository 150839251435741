module.exports = {
	"email": "Email",
	"phone": "Phone",
	"name": "Name",
	"avatar": "Profile picture",
	"save": "Save",
	"saved": "Saved",
	"saving": "Storing",
	"edit": "Edit",
	"create": "Create",
	"remove": "Delete",
	"reinvite": "Re-invite",
	"createUser": "Create user",
	"loading": "Loading {resource}",
	"dashboard": "Dashbord",
	"project": "Project | Projects",
	"theProject": "The project",
	"map": "Map",
	"yourProject": "Your project | Your projects",
	"allProjects": "All projects",
	"noProjects": "No projects",
	"residential": "Home | Homes",
	"noResidentials": "No homes",
	"residentialTemplate": "Home type | Home types",
	"createResidentialTemplate": "Create home type",
	"createUnitTemplate": "",
	"user": "User | Users",
	"settings": "Settings",
	"login": "Login",
	"logout": "Logout",
	"termsCondition": "Contract terms and conditions",
	"privacyPolicy": "Privacy",
	"cookiePolicy": "Cookie Policy",
	"menu": "Menu",
	"followUs": "Follow us",
	"getStarted": "Get started",
	"noAccess": "No access",
	"projectName": "Project name",
	"publish": "Publish",
	"publishing": "Publishing",
	"published": "Published",
	"unpublished": "Not published",
	"domain": "Domain",
	"retry": "Try again",
	"close": "Close",
	"showDetails": "Show details",
	"hideDetails": "Hide details",
	"createLead": "New interested party",
	"newLead": "New interested party",
	"lead": "Interest party | Interested parties",
	"leadMessage": "Notification",
	"message": "Message | Messages",
	"newMessage": "New message",
	"comment": "@:message",
	"noMessages": "No messages",
	"description": "Description",
	"showMore": "Show more",
	"noLeads": "No interested parties",
	"openhouse": "Open house | Open houses",
	"openhouseOnRequest": "Open house on request",
	"noOpenhouse": "No open houses",
	"createOpenhouse": "New open house",
	"from": "From",
	"to": "To",
	"date": "Date",
	"role": "Role | Roles",
	"flatfinder": "Home finder | Home finders",
	"unitSelector": "",
	"flatfinderTypeFlat": "Flat finder | Flat finders",
	"flatfinderTypePlot": "Plot finder | Plot finders",
	"flatfinderTypeOffice": "",
	"createFlatfinder": "New home finder",
	"createUnitSelector": "",
	"home": "Home",
	"statistics": "Statistics",
	"notset": "Not determined",
	"projectmanager": "Project associate | Project associates",
	"customermanager": "Customer service representative | Customer service representatives",
	"complaintmanager": "",
	"search": "Search",
	"searchFor": "Search for",
	"cancel": "Cancel",
	"confirm": "Confirm",
	"couldnotfindonquery": "No hits for \"{query}\"",
	"selectx": "Select {x}",
	"brochure": "Prospect",
	"downloadBrochure": "Download prospect",
	"logo": "Logo",
	"logoInverted": "Logo (bright version)",
	"favicon": "Favicon (Browser icon)",
	"gallery": "Gallery",
	"internalId": "Internal ID",
	"createProject": "New Project",
	"address": "Address",
	"postcode": "Postal code",
	"city": "Place",
	"county": "County",
	"latitude": "Longitude",
	"longitude": "Latitude",
	"position": "Position",
	"createResidential": "New home",
	"cadastre": "Cadastre",
	"residentialnumber": "Property unit number",
	"number": "Number",
	"street": "Street address",
	"floorplan": "Floor plan | Floor plans",
	"dragImageOrDrop": "Drag the image here or <b>click</b> to upload | Drag images here or <b>click</b> to upload",
	"dragFilesOrDrop": "Drag files {extension} here or <b>click</b> to upload",
	"dragFileOrDrop": "Drag file {extension} here or <b>click</b> to upload",
	"price": "Price",
	"residentialProperties": "Key info",
	"propertyType": "Home type",
	"propertyTypeWarning": "<b>NOTE!</b> You have selected home type \"{value}\" and must therefore enter values per unit.",
	"numberOfBedrooms": "Bedrooms",
	"numberOfRooms": "Rooms",
	"numberOfBathrooms": "Bathrooms/WC",
	"primaryRoomArea": "Primary rooms",
	"grossArea": "Gross building area",
	"useableArea": "Usable floor space",
	"floor": "Storey",
	"numberOfFloors": "Number of storeys",
	"plotArea": "Building plot area",
	"ownershipType": "Ownership",
	"numberOfParkingSpots": "Number of parking spots",
	"energyLabel": "Energy labelling",
	"plotOwned": "Freehold property",
	"plotGroundTax": "Property tax",
	"plotOwnershipType": "Ownership (plot)",
	"communityTax": "Municipal taxes",
	"taxValue": "Tax value",
	"constructionYear": "Construction year",
	"renovatedYear": "Year renovated",
	"value": "Value",
	"add": "Add",
	"custom": "Self-defined",
	"yes": "Yes",
	"no": "No",
	"quantity": "Quantity",
	"none": "None",
	"bid": "Purchase",
	"addBid": "New bid",
	"bidUrl": "Link to bidding",
	"purchaseUrl": "Link for signing of purchase contract",
	"size": "Size",
	"sale": "Sale",
	"basis": "Basis",
	"presentation": "Presentation",
	"integration": "Integration | Integrations",
	"next": "Next",
	"prev": "Previous",
	"cover": "Cover",
	"propertyType:detached": "Detached house | Detached houses",
	"propertyType:flat": "Flat | Flats",
	"propertyType:terraced": "Townhouse",
	"propertyType:semidetached": "Duplex",
	"propertyType:plot": "Residential plot | Residential plots",
	"propertyType:leisure": "Cottage | Cottages",
	"propertyType:leisurePlot": "Recreational plot | Recreational plots",
	"propertyType:office": "",
	"openhouseReferences": "Where are open houses taking place",
	"isInterestedIn": "Interested in",
	"leadReferences": "@:isInterestedIn",
	"theWholeProject": "The entire project",
	"getInTouch": "Contact us",
	"leadFormTitle": "Notify your interest",
	"leadConfirm": "Thank you for your interest",
	"residentialStatus": {
		"draft": "@:unpublished",
		"upcoming": "Coming soon",
		"sale": "For sale",
		"sold": "Sold",
		"reserved": "Reserved"
	},
	"residentialStatus:sale": {
		"draft": "@:unpublished",
		"upcoming": "Coming soon",
		"sale": "For sale",
		"sold": "Sold",
		"reserved": "Reserved"
	},
	"residentialStatus:rent": {
		"draft": "@:unpublished",
		"upcoming": "Coming soon",
		"reserved": "Reserved",
		"rent": "For rent",
		"rented": "Rented"
	},
	"viewResidentials": "View homes",
	"status": "Status",
	"bidAction": "Purchase",
	"residentialSaleFee": "Expenses",
	"residentialSaleFixed": "Fixed price",
	"residentialSaleFeeIncluded": "Specified price includes expenses",
	"contact": "Contact | Contacts",
	"editContact": "Change contact",
	"createContact": "Create contact",
	"contactUpsell": "I wish to receive information about similar projects",
	"leadPrivacy": "I wish to be contacted with the consent of {0}",
	"contractPrivacy": "I am aware that {0}",
	"salesmanVisibility": "The contact information for customer service representatives on this project will be displayed on the project website",
	"areYouSure": "Are you sure?",
	"somethingWentWrong": "Something went wrong",
	"completed": "Completed",
	"complete": "Complete",
	"completing": "Completing",
	"youAreAboutToRemove": "You are deleting",
	"removeRelationsTitle": "You are also deleting the following:",
	"showAll": "Show all",
	"attachment": "Attachment | Attachments",
	"preview": "Preview",
	"transferToUser": "Move associated data to:",
	"upcomingDeadlines": "Upcoming deadlines",
	"noDeadlines": "No upcoming deadlines",
	"recentActivity": "Recent events",
	"noActivity": "No events",
	"task": "Task | Tasks",
	"noTasks": "No tasks",
	"new": "New",
	"showCoordinates": "Show coordinates",
	"readOurTerms": "Read the contract terms and conditions",
	"iAcceptTerms": "I have read and accept the contract terms and conditions",
	"sendAndAccept": "Proceed",
	"privacy": "Privacy Policy",
	"notfoundTitle": "The page does not exist",
	"projectWillPublish": "Published soon",
	"projectWillPublishAt": "Published on {date}",
	"projectUnpublished": "Project has ended",
	"pageMadeBy": "The page is created in",
	"processedBy": "Processed by",
	"markAsProcessed": "Mark as processed",
	"openhouseAtValue": "Open house for {value}",
	"noMoreResidentials": "No additional homes",
	"welcomeName": "Welcome {name}",
	"youHaveBeenAddedToCompany": "You have been added as a user in {company}.",
	"activateAccount": "Activate account",
	"pressButtonToActivateAccount": "Click the button to select your password and active the account.",
	"orgNo": "Org. no.",
	"howToGetLatLng": "To have the project placed on the map, we recommend that you use {0} to retrieve the correct coordinates. Address lookup often does not contain new addresses and you will therefore have to retrieve these manually. The position will also be more accurate.",
	"image": "Image | Images",
	"flatfinderBuilder": "Setup",
	"leaflet-v1": {
		"drawToolbar": {
			"actions": {
				"title": "Cancel drawing",
				"text": "Cancel"
			},
			"finish": {
				"title": "Complete drawing",
				"text": "Complete"
			},
			"undo": {
				"title": "Delete the last drawn point",
				"text": "Delete the last point"
			},
			"buttons": {
				"polygon": "Draw a selection"
			}
		},
		"drawHandlers": {
			"polygon": {
				"error": "Error!",
				"tooltip": {
					"start": "Click to draw a selection.",
					"cont": "Click to continue drawing a selection.",
					"end": "Click the first point to close this selection."
				}
			}
		},
		"editToolbar": {
			"actions": {
				"save": {
					"title": "Save changes.",
					"text": "Save"
				},
				"cancel": {
					"title": "Cancel editing, discard all changes.",
					"text": "Cancel"
				},
				"clearAll": {
					"title": "Delete all selections.",
					"text": "Delete all"
				}
			},
			"buttons": {
				"edit": "Edit selections.",
				"editDisabled": "No selections to edit.",
				"remove": "Delete selections.",
				"removeDisabled": "No selections to delete."
			}
		},
		"editHandlers": {
			"edit": {
				"tooltip": {
					"text": "Drag the points or select to edit.",
					"subtext": "Click cancel to discard changes."
				}
			},
			"remove": {
				"tooltip": {
					"text": "Click a selection to delete."
				}
			}
		}
	},
	"theme": "Theme",
	"selectTheme": "Select theme",
	"show": "Display",
	"activateMarketingButton": "Purchase additional visibility",
	"couldNotLoadContent": "Unable to load content",
	"couldNotLoadContentMessage": "Try to refresh the page. If the problem persists, please contact {0}.",
	"personalInformation": "Personal data",
	"bidInformation": "Bid information",
	"bidAuthentication": "Identification",
	"bidSigning": "Signing",
	"bidConfirmation": "Bid signed",
	"purchaseSigning": "Signing",
	"purchaseConfirmation": "Purchase contract signed",
	"socialSecurityNumber": "Social Security number",
	"bidApplicant": "Buyer",
	"bidHasCoApplicant": "Add co-applicant",
	"purchaseHasCoApplicant": "@:bidHasCoApplicant",
	"bidCoApplicant": "Co-applicant",
	"amount": "Amount",
	"bidExpiresAt": "Acceptance deadline",
	"bidTakeoverAt": "Desired takeover",
	"bidCondition": "Possible reservations",
	"passwordEnforcementMessage": "The password must consist of at least 8 characters, upper/lower case letters and at least 1 number",
	"bank": "Bank",
	"financeContact": "Contact person",
	"financingPlan": "Payment plan",
	"signingAuthority": "Authorised signatory",
	"bidContract": "Purchase confirmation",
	"purchaseContract": "Purchase contract",
	"shopContract": "Addon contract",
	"shop-partialContract": "Addon contract - Partial signing",
	"bidSignedMessage": "Your bid has been signed and sent. You will shortly receive a confirmation by email. As soon as your bid has been processed, you will receive a reply by email.",
	"purchaseSignedMessage": "Your purchase contract has been signed and sent. You will shortly receive a confirmation by email. As soon as your purchase contract has been processed, you will receive a reply by email.",
	"gotoProject": "Go to the project",
	"parent": "Parent",
	"goBack": "Back",
	"removeDemoContent": "Delete demo content",
	"unsavedChangesDialog": "You have unsaved changes that will be lost if you continue. Are you sure you want to continue?",
	"confirmation": "Confirmation",
	"yourDocumentsAreAttached": "Please find attached all documents",
	"accept": "Approve",
	"decline": "Reject",
	"document": "Document | Documents",
	"noDocuments": "No documents",
	"projectsite": "Project website",
	"signer": "Undersigned | Undersigned",
	"youDoNotHaveSignAuthority": "Only the authorised signatory can approve/reject this document",
	"update": "Update",
	"fieldsToSync": "Data to be overwritten",
	"selectAll": "Select all",
	"residentialTemplateSyncReferences": "Update associated homes",
	"askResidentialTemplateSyncReferences": "Do you wish to update associated homes?",
	"projectDomainSetup": "For the change to become visible online, you have to set up a DNS pointer for your domaine (see below). Remember that it may take a bit of time before the change becomes visible online. Contact us by chat if you have questions.",
	"lookingForAUserAdd": "Can’t find the user you are looking for?",
	"select": "Select",
	"syncReferencesWarning": "Be careful when selecting what data is to be overridden. All selected fields will be overridden with new content.",
	"themeDoesNotSupportColor": "The selected theme does not support the option for theme colour.",
	"themeColor": "Theme colour",
	"youAreHere": "You are here",
	"resetSelection": "Reset selections",
	"selection": "Selections",
	"thisIsADemo": "This is a demo",
	"userIsGuest": "Guest / Does not have access to the system",
	"guest": "Guest",
	"inviteSent": "Invitation sent",
	"demo": "Demo",
	"thisMessageHasBeenSentTo": "This message has been sent to",
	"tourSeoAnalyticsTagManager": "SEO / Analytics / Tag Manager",
	"tourDeveloperTools": "Use of development tools",
	"tourBusinessSite": "How to change company page",
	"tourRecommendedImageFormat": "Recommended image formats",
	"tourHowToAddUser": "How to add a user",
	"tourHowToCreateProjects": "How to create projects",
	"tourSingleResidentialSale": "Simple home sale",
	"tourHowToAddLeads": "How to add interested parties",
	"tourHowToCreateResidentials": "How to create homes",
	"addonPresentation": "Presentation",
	"addonBid": "Bidding",
	"addonPurchase": "Purchase contract",
	"addonShop": "@:shop",
	"addonShopPurchase": "Addon shop - Signing",
	"tourAboutProjectDashboard": "About the project dashboard",
	"tourHowToEditResidentials": "How to edit homes",
	"tourHowToDeleteResidentials": "How to delete homes",
	"tourTitleOnScreen": "Help guides (Interactive)",
	"tourTitleCourseVideos": "Instructional videos",
	"tourTitleManuals": "Help guides (Manual)",
	"flatfinderIsEntry": "Main home finder (show this first on the project page)",
	"flatfinderCreateResidentialWarning": "When you setup a home finder you need to connect to homes. Therefore, it is important that you first create the homes before setting up the home finder.",
	"flatfinderCreateResidentialWarningTitle": "We see that you have not created any homes",
	"flatfinderCreateResidentialWarningException": "If you are not going to setup homes at this time, you can disregard this message and continue.",
	"continue": "Continue",
	"default": "Standard",
	"richTextUpdateLink": "Update link",
	"richTextAddLink": "Add link",
	"multiplicatorMonthly": "Months",
	"multiplicatorProject": "Project",
	"contractStatusAccepted": "Approved",
	"contractStatusDeclined": "Rejected",
	"contractStatusDraft": "Draft",
	"contractStatusSigned": "A document is awaiting approval",
	"contractStatusUnsigned": "You have a document for signing",
	"bidErrorStatus": "The home is not for sale",
	"bidErrorAddon": "Module for bidding is not activated for this project",
	"purchaseErrorStatus": "The home is not sold",
	"purchaseErrorAddon": "Module for purchase contract is not activated for this project",
	"signed": "Signed",
	"gotoSigning": "Go to signing",
	"sign": "Sign",
	"username": "Username",
	"accountUrl": "Link to account",
	"password": "Password",
	"otherBidAcceptedMessage": "Another bid was accepted",
	"bidConfirmationMessage": "We have received your bid and you will receive a reply from us as soon as possible",
	"bidAcceptMessage": "You bid has been accepted",
	"bidDeclinedMessage": "You bid has been rejected",
	"purchaseConfirmationMessage": "We have received your purchase contract and you will receive a reply from us as soon as possible",
	"purchaseAcceptMessage": "Your purchase contract has been accepted",
	"purchaseDeclinedMessage": "You purchase contract has been rejected",
	"shopConfirmationMessage": "We have received your signed addon order. You will receive a confirmation once the counterparty has signed it. Please find attached an overview of your order.",
	"shopAcceptMessage": "Your addon contract has been accepted",
	"shopDeclinedMessage": "Your addon contract has been rejected",
	"contractExpiredMessage": "Deadline has expired",
	"reason": "Reason",
	"pressHereToCancelBid": "Do you want to cancel the purchase?",
	"youAreUsingBidAddon": "You are using Kvass - Purchase module",
	"pressHere": "Click here",
	"void": "Cancel",
	"reasonWillBeSentToApplicants": "This message will be forwarded to the customer",
	"howToBuildContract": "For the document to be correctly filled out, you need to set up a fillable PDF. Click the help button below to see how you can do this.",
	"contractType": "Type",
	"createDocument": "Create document",
	"upload": "Upload",
	"bidUploadInfo": "After you have uploaded the signed purchase confirmation, the home will be marked as sold",
	"onSoldStatusDialog": "To mark this home as sold, you must upload the signed purchase confirmation.",
	"excludingVat": "excl. VAT.",
	"includingVat": "incl. VAT.",
	"priceOnRequest": "Price available upon request",
	"priceFrom": "Price from",
	"pricePerMonth": "Price per month",
	"salePrice": "Sales price",
	"priceMargin": "Markup",
	"purchasePrice": "Cost price",
	"readMore": "Read more",
	"upsaleRequestTemplate": "I want help with \"{0}\"",
	"publishedFrom": "Published from",
	"publishedTo": "Published to",
	"customerService": "Customer service",
	"residentialTemplateExplanation": "By creating home types you avoid having to update the same basis several times. You can quickly and easily update all homes/flats that are based on this home type, while at the same time you retain the option of changing the homes/flats individually at the project level.",
	"unitTemplateExplanation": "",
	"product": "Product | Products",
	"createProduct": "Create product",
	"allProducts": "All products",
	"category": "Category | Categories",
	"productCategorySample": "e.g. Floor, Wood floor, Heating, Cooling & Plumbing",
	"more": "More",
	"residentialStatusBidLock": "You have activated “Purchase module” and cannot change the status to or from “sold” manually. Access home to change status",
	"noAnalyticsCollected": "We do not yet have enough total data. You will see statistics here as soon as people visit the website.",
	"sendEmail": "Send email",
	"customThemeActiveMessage": "You are using a customised template for this project.",
	"validDnsSetupMessage": "The domain is not correctly set up - check the instructions below and try again.",
	"siteSettings": "Page settings",
	"showOpenhouseOnRequestIfNone": "Show \"@:openhouseOnRequest\" if no open houses are planned",
	"showLeadUpsell": "Show \"@:contactUpsell\" on the interested party form",
	"showShopOnResidentialPage": "Show addon shop on the home site",
	"showShop": "Show addon shop",
	"typeHere": "Write here",
	"shopCategoryExamples": "e.g., Kitchen, Bathroom",
	"shopSubcategoryExamples": "e.g. Tiles, Interior design",
	"shopAddProduct": "Select product",
	"shopAddCategory": "@:addCategory",
	"shopAddSubcategory": "Add sub-category",
	"lookingForAProductAdd": "Can’t find the product you are looking for?",
	"hideSold": "Hide sold",
	"assetManagerTitleBrochureDirect": "Download prospect",
	"assetManagerTitleBrochureLead": "Download prospect",
	"assetManagerTitleBrochureRequest": "Receive copy of prospect",
	"assetManagerActionBrochureLead": "Download prospect",
	"assetManagerActionBrochureRequest": "Send",
	"assetManagerFeedbackBrochureRequest": "You will receive the prospect shortly.",
	"assetManagerCommentBrochureRequest": "I wish to receive a copy of the prospect.",
	"brochureDownloadStrategyLabel": "Select how the prospect can be downloaded",
	"brochureDownloadStrategyDirect": "Download directly",
	"brochureDownloadStrategyLead": "Notify interest first",
	"brochureDownloadStrategyRequest": "Send enquiry/Send manually",
	"selected": "Selected",
	"shopDefaultProduct": "Standard delivery",
	"shopProductUpgrade": "Upgrade options",
	"totalPrice": "Total price",
	"shopContactSellerForOtherInquiries": "If you have other requests, please contact the seller",
	"shopNotAvailableSize": "Open this page on a larger display to get the best experience of the addon shop",
	"gotoItem": "Go to {0}",
	"activate": "Activate",
	"whatDoYouWantToCopy": "What should be copied?",
	"helpTools": "Manuals/Guides",
	"needHelp": "Do you need assistance?",
	"title": "Title",
	"nearbyarea": "Area",
	"shopSync": "Copy",
	"shopSyncAll": "The entire addon shop",
	"badge-bid": "@:bid",
	"badge-comment": "@:comment",
	"badge-openhouse": "@:openhouse",
	"badge-shopcontract": "@:addonShop",
	"badge-lead": "@:lead",
	"badge-purchase": "@:addonPurchase",
	"badge-task": "Task",
	"badge-customcontract": "",
	"prepareContract": "Preparation of contract",
	"prepareShop": "Preparation of addons",
	"preparePurchaseContract": "Preparation of purchase contract",
	"buyer": "Buyer | Buyers",
	"sendToSigning": "Send for signing",
	"deadline": "Deadline",
	"shopLink": "Link to addon shop (presentation)",
	"shopLinkWithSelection": "Link to addon shop (options & signing)",
	"shopSigning": "Signing",
	"shopConfirmation": "Confirmation",
	"shopIntro": "Introduction",
	"shopSummary": "Summary",
	"shopSignedMessage": "Your addon contract has been signed and sent. You will shortly receive a confirmation by email. As soon as your addon contract has been processed, you will receive a reply by email.",
	"shopSigningNotReady": "Signing is not yet available and you will be notified as soon as you can sign",
	"startShopContract": "Start addon process",
	"shopReadyTitle": "Your addon shop is ready",
	"shopReadyMessage": "You can now view and select what addons you want for your home. \nSigning is not yet available but you can already now make your first selections. You will be notified as soon as signing is available.",
	"shopWelcomeMessage": "Welcome to your addon shop - here you can view and select what addons you want for your home.\n\nAs soon as you have made your selections, you can proceed to the summary where you will have the option of signing your order.",
	"shopUnsignedMessage": "You can now select and sign your addons",
	"gotoShop": "Continue to the addons shop",
	"shopContractStatusReady": "Ready (sent to buyer)",
	"shopContractStatusDraft": "Draft",
	"shopContractStatusUnsigned": "Awaiting signature",
	"shopContractStatusSigned": "@:signed",
	"shopContractStatusExpired": "The deadline has expired",
	"shopContractNotSold": "The home is not yet for sale. As soon as the status of the home is changed to sold, you will be able to commence the signing process.",
	"shopContractAttachment": "Attachments (to be signed by buyer)",
	"shopContractStartRequirements": "Before you can start the addon process, you must enter buyer. \nRemember to save your changes. ",
	"shopContractRememberSignableFrom": "Remember to include \"@:signableFrom\" so that the buyer can sign the addon order.",
	"file": "File | Files",
	"import": "Import",
	"productImport": "Product import",
	"importedXItem": "Imported {count} {item}",
	"importFailedResolveIssues": "The import could not be implemented. Below, you will find an overview of what has gone wrong. Please correct and try again.",
	"pressButtonBelowToGetStarted": "Click the button below to get started",
	"signingDeadlineIs": "The deadline for signing is",
	"shopLinkPresentation": "Presentation",
	"shopLinkSigning": "Options / Signing (for buyer)",
	"export": "Export",
	"page": "Page | Pages",
	"tourGetStartedSingleShop": "How to get started with: Individual module - Addons",
	"tourHowToAddPartDeadlinesShop": "How to set sub-deadlines for addon signing",
	"tourHowIfOrIfNotShopWork": "How the addon process works",
	"tourGetStartedShop": "How to get started with: Addon shop",
	"tourGetStartedShopContract": "How to get started with: Digital addon signing",
	"tourTaskManagement": "How task management works in the customer follow-up system",
	"tourGetStartedPurchaseContract": "How to get started with: Digital purchase contract",
	"tourGetStartedBidContract": "How to get started with: Digital purchase module",
	"tourGetStartedSingleBidContract": "How to get started with: Individual module - Digital purchase module",
	"tourHowToEditProjectResidentials": "How to edit the project/homes",
	"tourHowToCreateFlatfinder": "How to create/edit home finders",
	"tourHowToCreateResidentialTemplate": "How to create/edit home types",
	"tourHowToBuildContract": "How to set up purchase confirmation",
	"tourHowDashboardWorks": "How the dashboard works",
	"tourHowToCreateProjectTeaser": "How to create a teaser (Coming soon)",
	"tourRoles": "How to administer users",
	"tourHowToPublish": "How to publish the project under a domain",
	"tourTitleAdditionalServices": "Would you like assistance?",
	"tourHowToBuildPDF": "How to set up a fillable PDF",
	"tourHowToHandleLead": "How to manage interested parties",
	"tourHowToHandleOpenhouse": "How to manage open houses",
	"howManyUnitsOnProject": "How many units in total are being sold?",
	"attachmentIsEncryptedWithSSN": "Note that the attachment is encrypted - the password is your national ID number. The document you have received is a copy and the original is with the seller.",
	"contractApprovalWarning": "Remember to check the content of the document before approval.",
	"addon:project": "Project",
	"addon:project:presentation": "Project / Home site",
	"addon:project:bid": "Purchase module",
	"addon:project:purchase": "Purchase contract",
	"addon:project:shop": "Addons - Presentation",
	"addon:project:shop-purchase": "Addons - Sale",
	"addon:custom-contract": "",
	"addon:api": "",
	"addon:flatfinder": "",
	"addon": "Module | Modules",
	"fixedPrice": "Fixed price",
	"areaPrice": "Price per m²",
	"customerPrice": "Price for customer",
	"establishment": "Establishment",
	"ownershipType:leasehold": "Cooperative",
	"ownershipType:freehold": "Owner (Condominium)",
	"ownershipType:other": "Other",
	"ownershipType:partOwnership": "Share",
	"ownershipType:collectiveOwnership": "Stock",
	"plotOwnershipType:leasehold": "Cooperative",
	"plotOwnershipType:freehold": "Owner (Condominium)",
	"plotOwnershipType:other": "Other",
	"plotOwnershipType:partOwnership": "Share",
	"plotOwnershipType:collectiveOwnership": "Stock",
	"subpage": "Subpage | Subpages",
	"orderItem": "Order {item}",
	"ShopPurchaseRequiresShop": "To be able to use “Addon - Sale”, you also need “Addon - Presentation”",
	"projectUnitLimitReached": "You can create {0} units for this project. To add additional units, you need to upgrade to an expanded licence. Please contact support@kvass.no or chat with us to upgrade.",
	"slug": "Slug",
	"content": "Content",
	"setArea": "Set area",
	"rounding:none": "None",
	"priceRounding": "Price rounding",
	"exampleShort": "e.g.,",
	"productOverrideMessage": "{value} is linked to a product catalogue. Click “edit” to set a separate value.",
	"productOverrideReset": "Link \"{value}\" to a product catalogue - remove separate value.",
	"priceMissingArea": "Area missing",
	"total": "Total",
	"instagramProfile": "Instagram profile",
	"numberOfPosts": "Number of posts",
	"followUsOn": "Follow us at {value}",
	"customFieldsResidentialTemplatesLabel": "Presentation of home types",
	"signableFrom": "Can be signed from",
	"setPartialDeadlines": "Set sub-deadlines",
	"removePartialDeadlines": "Remove sub-deadlines",
	"partialDeadline": "Sub-deadline | Sub-deadlines",
	"madeBy": "A service from",
	"gotoSummary": "Go to summary",
	"selectCategoriesToSign": "Select categories to be signed",
	"shopBuyerStatusDraft": "No yet available for signing",
	"shopBuyerStatusUnsigned": "Ready for signing",
	"wholeShop": "The entire addon shop",
	"company": "Company",
	"companyName": "Company name",
	"businessSite": "Company page",
	"private": "Private",
	"footer": "Footer",
	"creatingContracts": "Creating documents for signing, please wait a moment",
	"pressButtonBelowToGoToShop": "Click the button below to proceed to your addon shop",
	"youHaveNewShopInfo": "You have received updated information in your addon shop",
	"youHaveAnUpcomingDeadline": "You have an upcoming deadline",
	"hiName": "Dear {name}",
	"shopPresentationSidebarDescriptionTitle": "Set up you home",
	"shopPresentationSidebarDescriptionContent": "Here, you can view what upgrades are available for this home.",
	"signingLoadingMessage": "We are preparing your documents - please wait a moment (this will take approx. 15-30 sec.)",
	"tag": "Label | Labels",
	"noTags": "No labels",
	"youHaveANewMessageFrom": "You have received a new message from {from}",
	"nameSays": "{name} says",
	"gotoDialog": "Go to the conversation",
	"youCanAswerThisEmail": "You can reply to this email - your reply will be forwarded",
	"send": "Send",
	"note": "Note",
	"thisMessageWasSentWith": "This message has been sent with",
	"addCategory": "Add to category",
	"availableIf": "Available if",
	"availableIfNot": "Not available if",
	"xActiveConditions": "No active rules | {count} active rule | {count} active rules",
	"setConditions": "Set rules",
	"displayName": "Open house name",
	"useDisplayName": "Use a different open house name",
	"statisticsVisitors": "Visitor numbers",
	"statisticsSalesResidentials": "Sales numbers",
	"statisticsSalesShop": "Sales numbers (Addons)",
	"externalUserAlert": "You are attempting to add a user who is outside your organisation ({domain}). \nThe person in question will have access to the projects and accompanying data in the solution.\n\nPlease confirm that you wish to add {email}.",
	"sendMessageToSeller": "Send a message to the seller",
	"sendMessageToSellerButtonLabel": "Questions or concerns? – Contact us here",
	"sent": "Sent",
	"member": "Member | Members",
	"editTask": "Edit task",
	"createTask": "Create task",
	"undone": "Not commenced",
	"noDescription": "No description",
	"noDeadline": "No deadline",
	"youHaveANewTask": "You have received a new task",
	"gotoTask": "Go to the task",
	"youHaveX": "",
	"gotoDashboard": "Go to the dashboard",
	"youHaveXTaskDueIn": "You have {count} task that is due by {dueby} | You have {count} tasks that are due by {dueby}",
	"projectAdditionalFieldsDescriptionFooter": "Add footer. e.g.: About us/Access",
	"projectAdditionalFieldsDescriptionResidentailTemplate": "Select which of your home types you wish to present on the project page.",
	"projectAdditionalFieldsDescriptionArea": "Enter text and images to present the area or similar.",
	"projectAdditionalFieldsDescriptionSubpage": "Create subpages where you can present images and text. You can link to subpages by adding links in the text fields.",
	"clone": "Duplicate",
	"type": "Type | Types",
	"filter": "Filter",
	"noShopDefaultProduct": "No standard delivery",
	"customer": "Customer | Customers",
	"purchaseManuallyConfirmed": "Uploaded purchase contract is signed",
	"cannotAddMoreUsers": "You cannot select additional users. Please contact support if you wish to add additional users.",
	"videoPreviewMessage": "Add to {type} link for preview of video",
	"videoLink": "{type} link",
	"profile": "Profile | Profiles",
	"redirectToExternalSite": "Redirect to external site",
	"residentialRedirectUrlExample": "e.g., https://...",
	"optional": "optional",
	"residentialRedirectUrlExplanation": "Use this field if you wish to direct visitors to another website",
	"createdAt": "Create it",
	"overview": "Overview",
	"mediaTypeComboMessage": "Or click on the button to select from other types of media",
	"mediaTypeDefaultMessage": "Select media type via the button",
	"userIsDeveloper": "Show development tools",
	"noFloorplansSetup": "No floor plans set up",
	"mark": "Select",
	"createResidentialTemplateBeforeShop": "To create the addon shop you must first create the home type",
	"noShopsetsSetup": "No product packages are set up",
	"shopSet": "Product package | Product packages",
	"productsThatArePartOfSet": "Products included in the product package",
	"manualSelectAndSkip": "Select on you own (Skip)",
	"event": "Incident | Incident",
	"url": "URL",
	"enabled": "Activated",
	"webhook": "Webhook | Webhooks",
	"createWebhook": "Create webhook",
	"setting": "Setting | Settings",
	"webhookSecret": "Secret",
	"attempt": "Attempt",
	"of": "of",
	"log": "Log | Log",
	"noWebhooks": "No webhooks",
	"webhookIsSystem": "System integration",
	"noLogs": "No logs",
	"token": "Token | Tokens",
	"createToken": "Create token",
	"noTokens": "No tokens",
	"acl": "Access control",
	"tokenSecretMessage": "Please copy this key and store it in a safe place. For safety reasons, we will only show it once.",
	"shopContractNoticeSellerApproachingSubject": "Buyer’s signing deadline is expiring",
	"shopContractNoticeSellerOverdueSubject": "Buyer’s signing deadline has expired",
	"shopContractNoticeSellerExpires": "Buyer has not completed signing of addon contract.",
	"unit": "Unit | Units",
	"noUnits": "",
	"noMoreUnits": "",
	"createUnit": "",
	"unitTemplate": "",
	"residentialSaleWithBroker": "",
	"residentialSaleSelf": "",
	"residentialSaleWithBrokerBidHelperText": "Here, you can add an optional link with which you can sell the unit. The link will be available \nunder the “purchase” button on the home site. ",
	"bidContractMissingShowBidUrl": "Purchase confirmation missing. Upload purchase confirmation for the project to view bidding link. ",
	"residentialSaleBidHelperText": "With this link you can sell the unit digitally. \nThe link is available under the “purchase” button on the home site, but the link can also be copied from here. \nPurchases that are completed via this link will appear on the seller’s dashboard. ",
	"residentialSaleBidActiveHelperText": "Purchase module is activated. If you wish to use a different sales method you can order integration with the system. \nContact us via the button below for more information and ordering. ",
	"pressButtonBelowToGoToShopNoticeSeller": "Click the button for more information about the addon shop",
	"copy": "Copy",
	"copied": "Copied",
	"paywallContentFieldUsedByFollowingModules ": "These fields are not relevant for activated modules",
	"paywallContentPressButtonToReadMore": "Click on the button to read more or to activate the module | Click on one of the buttons to read more or to activate the module",
	"deliveryLogClickLabel": "{resource} delivered - opened",
	"deliveryLogOpenLabel": "{resource} delivered - seen",
	"deliveryLogFailedLabel": "{resource} could not be delivered",
	"deliveryLogDeliveredLabel": "{resource} delivered - not opened",
	"deliveryLogUnknownLabel": "{resource}  - awaiting opening",
	"invite": "Invitation",
	"info": "Info",
	"oneOrMoreDeadlinesExpired": "One or more deadlines has/have expired",
	"projectShopsOpenByBuyer": "Opened by buyer",
	"contractStatusNotAccepted": "Awaiting approval",
	"noMoreShops": "No additional addon shops",
	"projectShopsCreateResidentialsWarning": "Create homes before you set up addon shops.",
	"projectShopsEditShop": "Not created",
	"projectShopsCreateResidentials": "Create homes",
	"projectShopsNotSold": "Not sold",
	"projectSaleBrokerGuide1": "",
	"projectSaleBrokerGuide2": "",
	"projectSaleBrokerGuide3": "",
	"residentialsFlatfinderGuideInfo": "",
	"shop": "Addon shop | Addon shops",
	"devtools": "",
	"embed": "",
	"imageRecommendation": "",
	"height": "",
	"width": "",
	"imageFlatfinderRecommendation": "",
	"consentRequiredTitle": "",
	"consentRequiredDescription": "",
	"consentStatisticsTitle": "",
	"consentStatisticsDescription": "",
	"consentMarketingTitle": "",
	"consentMarketingDescription": "",
	"consentDialogTitle": "",
	"consentDialogDescription": "",
	"consentDialogLabelAccept": "",
	"consentDialogLabelAcceptAll": "",
	"consentDialogLabelConfirm": "",
	"consentDialogLabelConfigure": "",
	"editConsents": "",
	"noAttachments": "",
	"tourVideoUpload": "",
	"language": "Language",
	"textOverwriteWarning": "",
	"cookieVideoBlockMessage": "",
	"sizes": "",
	"productLink": "Product link",
	"showDefaultSelection": "",
	"noUpgradesSelected": "",
	"setValue": "",
	"internalName": "",
	"useInternalName": "",
	"shopWelcomeText": "",
	"scopedProduct": "",
	"contactImport": "",
	"importTemplateDescription": "",
	"socialMedia": "",
	"downloadTemplate": "",
	"projectCreationVideoMessage": "",
	"singleResidential": "",
	"myPage": "",
	"contractStatusNotAcceptedBySeller": "",
	"contractStatusAcceptedBySeller": "",
	"followSoMeTitle": "",
	"projectAdditionalFieldsSoMeDescriptionArea": "",
	"projectAdditionalFieldsSeoDescriptionArea": "",
	"projectAdditionalFieldsFacebookAdsDescriptionArea": "",
	"SEO": "",
	"facebookAds": "",
	"back": "Back",
	"newFolder": "New folder",
	"dragFolderOrDrop": "",
	"managementOperationMaintenance": "Management, Operation, Maintenance",
	"residentialAccount": "",
	"clickToFilter": "",
	"imagesOnlyAsIllustration": "",
	"foundXResults": "",
	"active": "Active",
	"inactive": "Inactive",
	"contract": "",
	"buyConfirmation": "Buy signed",
	"residentialAccountLogin": "",
	"loginPressButton": "",
	"loginVisitLink": "",
	"loginLinkExpires": "",
	"loginGetNewLink": "",
	"residentialAccountYourUnits": "",
	"residentialAccountLoginTooSeeUnits": "",
	"loginEnterValue": "",
	"loginSuccessMessage": "",
	"sortOn": "",
	"highLow": "",
	"lowHigh": "",
	"syncUser": "",
	"lowHighLetter": "",
	"highLowLetter": "",
	"addFilter": "",
	"resetFilter": "",
	"validTo": "",
	"registrationComplete": "",
	"clickToLogin": "",
	"welcomeToKvass": "",
	"enterPasswordAndClickComplete": "",
	"tokenExpiredRequestNew": "",
	"author": "",
	"post": "",
	"createPost": "",
	"shopShort": "",
	"developer": "",
	"access": "",
	"supplier": "",
	"technicalDescription": "",
	"addTechnicalDescription": "",
	"switchTenant": "",
	"leadMessageSent": "",
	"externalUserAlertAdmin": "",
	"externalUserAlertNoRights": "",
	"externalUserAlertRoles": "",
	"externalUserAlertAccess": "",
	"shopPresentationPreviewAlert": "",
	"addon:project:complaint": "",
	"archived": "",
	"showArchived": "",
	"archive": "",
	"integrationCompliance": "",
	"documentSigned": "",
	"documentSignedConfirmationMessage": "",
	"integrationNotificationEmailBody": "",
	"integrationNotificationEmailBodyReminder": "",
	"integrationNotificationEmailSubject": "",
	"noContracts": "",
	"awaitingSignature": "",
	"residentialAddressControl": "",
	"residentialAccountNotSold": "",
	"createContract": "",
	"lastUpdated": "",
	"complaint": "",
	"lookingForAContactAdd": "",
	"orderConfirmationCondition": "",
	"orderConfirmationConditionShort": "",
	"orderConfirmation": "",
	"unitLimitMessage": "",
	"taskStatusUndone": "",
	"taskStatusDone": "",
	"taskStatusRejected": "",
	"taskStatusRequest": "",
	"subjectArea": "",
	"showCompleted": "",
	"complaintRequest": "",
	"complaintTermsOfRequest": "",
	"complaintAddSelection": "",
	"complaintRegister": "",
	"companyOrder": "",
	"activatedBy": "",
	"orderConfirmationAttached": "",
	"install": "",
	"uninstall": "",
	"installed": "",
	"residentialAccountPublishAlert": "",
	"reset": "",
	"showLess": "",
	"residentialAccountAccess": "",
	"taskChangeStatus": "",
	"taskChangeStatusSubject": "",
	"residentialAccountSettingsShowShop": "",
	"residentialAccountSettingsShowShopSublabel": "",
	"residentialAccountSettingsShowShopDocuments": "",
	"residentialAccountSettingsShowShopDocumentsSublabel": "",
	"residentialAccountSettingsShowResidentialDocuments": "",
	"residentialAccountSettingsShowResidentialDocumentsSublabel": "",
	"redirectUrl": "",
	"contactSeller": "",
	"importFromCatalog": "",
	"reject": "",
	"rejected": "",
	"addon:project:flatfinder": "",
	"blockComplaints": "",
	"unblockComplaints": "",
	"complaintsBlocked": "",
	"taskStatusChangeToUndone": "",
	"taskStatusChangeToDone": "",
	"taskStatusChangeToRejected": "",
	"attachmentsSigningUploadWarning": "",
	"addonIsIncludedIn": "",
	"tourHowToEmbedFlatfinder": "",
	"helpers:roleManager": "",
	"helpers:roleSigningAuthority": "",
	"helpers:roleSalesman": "",
	"helpers:roleShopManager": "",
	"helpers:roleShopSigningAuthority": "",
	"helpers:roleShopSalesman": "",
	"salesmanShopVisibility": "",
	"productBundleContains": "",
	"productBundle": "",
	"rent": "",
	"saleType": "",
	"actionLabel:export": "",
	"actionLabel:integration": "",
	"action": "",
	"helpers:productBundle": "",
	"office": "Office",
	"integrationDpaRecommendation": "",
	"ordinaryProductPrice": "",
	"helpers:shopPricingMethodTitle:add": "",
	"helpers:shopPricingMethodDescription:add": "",
	"helpers:shopPricingMethodTitle:diff": "",
	"helpers:shopPricingMethodDescription:diff": "",
	"selectMethod": "",
	"priceCalculation": "",
	"unitNumber": "",
	"productUpdateAffectedProducts": "",
	"upcomingLabel": "",
	"hide": "",
	"residentialAdditionalFieldsLabel": "",
	"link": "",
	"loggingIn": "",
	"setDueDates": "",
	"complaintDescription": "",
	"helpers:managementOperationMaintenance": "",
	"helpers:roleComplaintManager": "",
	"residentialAccountLoginCondition": "",
	"noComplaints": "",
	"complaintDisclaimerConsent": "",
	"complaintDisclaimerEdit": "",
	"fdvControlLabel": "",
	"fdvControlSublabel": "",
	"shopEditDueAtUnitNotSold": "",
	"confirmed": "",
	"control": "",
	"noMembers": "",
	"complaintRoleMissing": "",
	"folder": "",
	"helpers:complaintsList": "",
	"completeTask": "",
	"changeStatus": "",
	"confirmRequest": "",
	"rejectRequest": "",
	"setTaskToUndone": "",
	"cannotEditMultipleResidentialFieldsSameSaleType": "",
	"noContacts": "No contacts",
	"filterEmptyState": "",
	"invoice": "",
	"receiver": "",
	"addon:project:presentation:sublabel": "",
	"addon:project:bid:sublabel": "",
	"addon:project:purchase:sublabel": "",
	"addon:project:shop:sublabel": "",
	"addon:project:shop-purchase:sublabel": "",
	"addon:project:residential-account:sublabel": "",
	"addon:project:residential-account": "",
	"billing": "",
	"featuredResidentialPropertyHelper": "",
	"viewProject": "",
	"unitSelectorIsEntry": "",
	"autocompleteAddressAlert": "",
	"externalFileDisclaimer": "",
	"getMarkerPosition": "",
	"createResource": "",
	"taskCommentsReceiverInfo": "",
	"createdBy": "",
	"openhouseEmptyState": "",
	"documentsEmptyState": "",
	"startShops": "",
	"noUnitTemplates": "",
	"unitTemplatesEmptyState": "",
	"noApplicantShopContractTooltip": "",
	"noUsers": "",
	"usersEmptyState": "",
	"cannotCreateMoreX": "",
	"cannotDelete": "",
	"noX": "",
	"thisFieldIsFetchedFromX": "",
	"addSecondaryColor": "",
	"primaryColor": "",
	"secondaryColor": "",
	"unitMustBeSoldToSetOwner": "",
	"owner": "",
	"tableFooterTip": "",
	"sendMessage": "",
	"mediaDescriptionPlaceholder": "Add caption",
	"taskTypeDefault": "",
	"taskTypeComplaint": "",
	"downloadX": "",
	"noUnsavedChanges": "",
	"installMore": "",
	"propertyType:four-person-home": "",
	"addCompanyInfo": "",
	"shopTotalLabel": "Total price for addons",
	"addDesiredAmount": "",
	"creationDate": "Creation date",
	"shop-partialConfirmationMessage": "We have received your signed addon order. You will receive a confirmation once the counterparty has signed it. Please find attached an overview of your order.",
	"shop-partialAcceptMessage": "Your addon contract has been accepted",
	"shop-partialDeclinedMessage": "Your addon contract has been rejected",
	"customConfirmationMessage": "We have received your contract and you will receive a reply from us as soon as possible",
	"customAcceptMessage": "Your contract has been accepted",
	"customDeclinedMessage": "You contract has been rejected"
}